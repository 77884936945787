app.service('sessions', ['$cookies', 'localStorageService', '$rootScope', '$http', '$location',
    function ($cookies, localStorage, $rootScope, $http, $location) {

    var that = this;

    const SESSION_KEY = 'X-XSRF-TOKEN';
    const LOCAL_KEY = 'PtuSessionData';
    const REDIRECT_KEY = 'ptuRedirectDestination';
    const REDIRECT_IGNORE_LIST = ['/login'];
    const SSO_HANDOFF_KEY = 'ssoHandoff';


    //Session CRUD operations
    that.getCurrent = function () {
        var authToken = $cookies.get(SESSION_KEY);
        var sessionData = localStorage.get(LOCAL_KEY);

        if (!authToken) {
            return null;
        }

        if (!sessionData) {
            var handoff = $cookies.get(SSO_HANDOFF_KEY);

            if (handoff) {
                $cookies.remove(SSO_HANDOFF_KEY);
                return that.create(JSON.parse(handoff));
            } else {
                return null;
            }
        }

        authToken = JSON.parse(authToken);
        sessionData = JSON.parse(sessionData)

        sessionData.expiration = authToken.experation;
        return sessionData;
    }  

    that.update = function (session) {
        localStorage.set(LOCAL_KEY, JSON.stringify(session));
    }

    that.end = function (username) {
        $cookies.remove(SESSION_KEY);
        $cookies.remove(SESSION_KEY, { path: '/api' }); //bug fix for when auth cookie was incorrectly not set to root path  
        localStorage.remove(LOCAL_KEY);
    }

    that.create = function (value) {
        var session = {
            id: value.SessionId,
            username: value.Account.UserId,
            fullname: value.Account.FullName,
            email: value.Account.Email,            
            isVendor: value.Account.IsVendor,
            isAgent: value.Account.IsMember,
            isAdmin: value.Account.IsAdmin,
            userOffices: value.Account.Offices,
            expiration: value.Expiration
        };

        if (value.Account.IsAdmin) {
            session.userType = 'Admin';
        } else if (value.Account.IsMember) {
            session.userType = 'Agent';
        } else {
            session.userType = 'Vendor';
        }

        localStorage.set(LOCAL_KEY, JSON.stringify(session));

        return session;
    };

    //error handlers
    that.reportHttpError = function (httpResponse) {
        $rootScope.$broadcast("httpError", httpResponse);
    }

    that.reportError = function (message, callback) {
        var data = {
            message: message,
            callback: callback
        };
        $rootScope.$broadcast("error", data);
    };

    that.reportInvalidSession = function (destination) {
        $rootScope.$broadcast("sessionTimeout", destination);
    }

    // bulma dropdown lists
    that.initDropdowns = () => {
        const getAllElements = selectorQuery => {
                return Array.prototype.slice.call(document.querySelectorAll(selectorQuery), 0);
            },
            dropdowns = getAllElements('.dropdown:not(.is-hoverable)'),
            toggleIsActive = function (e) {
                e.stopPropagation();
                (e.target || e.currentTarget).closest('.dropdown').classList.toggle('is-active');
            },
            closeAllDropdowns = function () {
                dropdowns.forEach(el => el.classList.remove('is-active'));
            },
            closeAllDropdownsByKey = function (evt) {
                const e = evt || window.event;
                if (e.key === 'Esc' || e.key === 'Escape') //|| e.key == 'Enter'
                    closeAllDropdowns();
            };

        dropdowns.forEach(el => {
            let $trigger = el.querySelector('.dropdown-trigger');
            if ($trigger) {
                $trigger.removeEventListener('click', toggleIsActive);
                $trigger.addEventListener('click', toggleIsActive);
            }
        });

        document.removeEventListener('click', closeAllDropdowns);
        document.addEventListener('click', closeAllDropdowns);

        document.removeEventListener('keydown', closeAllDropdownsByKey);
        document.addEventListener('keydown', closeAllDropdownsByKey);        
    };

    // verify current session
    that.verifyCurrentSession = function () {
        var session = that.getCurrent(),
            success = function (response) {
                session.expiration = response.data.expiration;
                that.update(session);
            },
            error = function (response) {
                that.reportHttpError(response);
            };

        if (session) {
            $http.get('./api/VerifySession').then(success, error);
        }
    }

    //session related redirects
    that.saveRedirect = function (redirect) {
        if (!REDIRECT_IGNORE_LIST.includes(redirect)) {
            console.log("Debug: redirect saved " + redirect);
            $cookies.put(REDIRECT_KEY, redirect);
        }
    }

    that.getRedirect = function (redirect) {
        var redirect = $cookies.get(REDIRECT_KEY);
        console.log("Debug: redirect read " + redirect);
        return redirect;
    }

    that.deleteRedirect = function () {
        console.log("Debug: redirect removed");
        $cookies.remove(REDIRECT_KEY);
    }

    //package header
    that.updatePackage = function (package) {
        var session = that.getCurrent();

        if (!package || !session.packages)
            return session;

        var index = session.packages.findIndex(p => p.MediaPackageId === package.MediaPackageId);

        if (index < 0)
            session.packages.unshift(package);
        else
            session.packages[index] = package;

        that.update(session);

        return session
    }
}]);